@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header-link {
    @apply text-white font-montserrat transition-all duration-300 hover:text-blue-400 cursor-pointer;
  }

  .social__icons li {
    @apply transition-all duration-300 hover:text-blue-400 cursor-pointer;
  }

  .social__icons {
    @apply text-white flex space-x-5 text-2xl font-montserrat;
  }
}

body::-webkit-scrollbar {
  @apply hidden
}

.active {
  @apply text-blue-400;
}

.before__header {
  @apply bg-[#808080]/70 py-2 transition-all duration-300 max-lg:hidden;
}

.bg-img {
  @apply bg-[url(./assets/images/counter-bg.png)];
}

.bg-imgRight {
  @apply bg-[url(./assets/images/right.png)];
}

#tsparticles {
  @apply w-full h-[750px] max-lg:h-[500px] absolute inset-0;
}

#scroll {
  @apply fixed cursor-pointer w-[50px] h-[50px] bg-mainColor indent-[-9999px] hidden rounded-[60px] right-2.5 bottom-2.5 hover:opacity-100;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
}

#scroll span {
  @apply flex justify-center items-center mt-3 -rotate-[44deg] stroke-white;
}

#scroll:hover {
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.lg-react-element {
  @apply flex flex-wrap pt-10 justify-between max-lg:flex-col max-lg:px-10
}

@layer base {
  @font-face {
    font-family: "montserrat";
    src: url("./assets/fonts/Montserratarm-Regular.otf");
  }

  @font-face {
    font-family: "montserrat-bold";
    src: url("./assets/fonts/Montserratarm-Bold.otf");
  }

  @font-face {
    font-family: "montserrat-extrabold";
    src: url("./assets/fonts/Montserratarm-ExtraBold.otf");
  }

  @font-face {
    font-family: "montserrat-extralight";
    src: url("./assets/fonts/Montserratarm-ExtraLight.otf");
  }

  @font-face {
    font-family: "montserrat-light";
    src: url("./assets/fonts/Montserratarm-Light.otf");
  }

  @font-face {
    font-family: "montserrat-medium";
    src: url("./assets/fonts/Montserratarm-Medium.otf");
  }

  @font-face {
    font-family: "montserrat-semibold";
    src: url("./assets/fonts/Montserratarm-SemiBold.otf");
  }

  @font-face {
    font-family: "montserrat-thin";
    src: url("./assets/fonts/Montserratarm-Thin.otf");
  }
}
